<template>
    <div>
      <input type="email" v-model="email" placeholder="이메일">
      <input type="password" v-model="password" placeholder="비밀번호">
      <button type="button" @click="loginFunction">로그인</button>
    </div>
</template>

<script>
import { supabase } from '../supabase';

export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async loginFunction() {
      // eslint-disable-next-line no-unused-vars
      const { user, error } = await supabase.auth.signIn({
        email: this.email,
        password: this.password
      });
      if (error) {
        // 오류 처리
        console.error(error);
      } else {
        console.log(user); // 사용자 정보 출력 또는 다른 처리
      }
    }
  }
};
</script>
