<template>
  <div id="app">
    <div class="top-nav">
      <img src="./assets/logo.png" alt="YK logo" class="logo">
      <input type="text" placeholder="검색" class="search-bar">
      <router-link to="/login" class="login-btn">로그인</router-link>
    </div>
    <HelloWorld msg="공지사항"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import HelloWorld from './components/HelloWorld.vue'

export default defineComponent({
  name: 'App',
  components: {
    HelloWorld
  },
  methods: {
    loginFunction() {
      this.$router.push('/login');
    }
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
html, body {
  margin: 0;
  padding: 0;
  /* 화면 전체에 걸쳐 요소들이 확장되도록 하기 위해 */
}
.logo {
  height: 50px;
  margin: 0 30px;
}
.top-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #efeae0;
  box-sizing: border-box;
}
.search-bar {
  flex-grow: 1;
  margin: 0 50px;
  max-width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.login-btn {
  padding: 5px 30px;
  font-size: 15px;
  background-color: black;
  color: white;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
.login-btn:hover {
  background-color: #797763;
}
</style>
