import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';

// 라우터 구성을 정의합니다.
const routes = [
  {
    path: '/login', 
    name: 'Login',
    component: Login
  },
  // 다른 라우트 설정...
];

// createRouter 함수를 사용하여 라우터 인스턴스를 생성합니다.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // HTML5 History 모드를 사용합니다.
  routes, // 위에서 정의한 라우트 구성을 여기에 할당합니다.
});

export default router;
