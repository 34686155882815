import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Vue 애플리케이션을 생성합니다.
const app = createApp(App);

// Vue Router를 애플리케이션에 사용하도록 설정합니다.
app.use(router);

// 애플리케이션을 HTML 페이지의 #app 엘리먼트에 마운트합니다.
app.mount('#app');
